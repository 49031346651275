@import "~variables";

.AccountStatusModal {
	padding: $spacing24;
	margin-top: $spacing16;
	box-sizing: border-box;
	min-height: 300px;
	display: flex;
	align-items: center;
	flex-direction: column;

	h1 {
		@include fontSize24;
		font-weight: $fontWeightRegular;
		margin-bottom: $spacing24;
		margin-top: $spacing24;
	}

	.Actions {
		display: flex;
		flex-direction: column;
		align-items: initial;
		text-align: center;

		.Link {
			margin-top: $spacing24;
			font-weight: $fontWeightMedium;
			color: var(--color-neutral-700);

			&:hover {
				color: var(--color-primary-600);
				cursor: pointer;
			}
		}
	}
}