$xsmall-width: 550px;
$small-width: 768px;
$medium-width: 850px;
$large-width: 992px;
$xlarge-width: 1150px;

@mixin xsmall-screen-only {
  @media screen and (max-width: #{$xsmall-width}) {
    @content;
  }
}

@mixin xsmall-screen {
  @media screen and (min-width: #{$xsmall-width}) {
    @content;
  }
}

@mixin small-screen {
  @media screen and (min-width: #{$small-width}) {
    @content;
  }
}

@mixin medium-screen {
  @media screen and (min-width: #{$medium-width}) {
    @content;
  }
}
@mixin large-screen {
  @media screen and (min-width: #{$large-width}) {
    @content;
  }
}

@mixin xlarge-screen {
  @media screen and (min-width: #{$xlarge-width}) {
    @content;
  }
}
