$spacing4: 0.25rem;
$spacing8: 0.5rem;
$spacing12: 0.75rem;
$spacing16: 1rem;
$spacing24: 1.5rem;
$spacing32: 2rem;
$spacing48: 3rem;
$spacing64: 4rem;
$spacing96: 6rem;
$spacing128: 8rem;

@mixin boxPad24() {
  padding: $spacing24;
}

@mixin boxPad16() {
  padding: $spacing16;
}

@mixin boxPad12() {
  padding: $spacing12;
}

