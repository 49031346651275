@import "~variables";

.ErrorsSpacing {
  transition: 0.3s ease height;
  height: 0;
  position: relative;
}

.Errors {
  visibility: hidden;
  transition: 0.3s ease-in visibility;
  position: absolute;
  @include shadowCloseNeutral;
  border-bottom-right-radius: $spacing8;
  border-bottom-left-radius: $spacing8;
  box-sizing: border-box;
  background-color: white;

  &.HasErrors {
    border-top: 10px solid var(--color-accent-red-500);
    padding: $spacing12;
  }

  p {
    margin: 0;
  }

  li {
    margin-top: 6px;
  }
}