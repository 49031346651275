@import "~variables";

.Delay {
	padding: 12px;
	border-radius: 8px;
	border: 1px solid var(--color-primary-100);
	background-color: var(--color-primary-100);
	max-width: 280px;

	.Details {
		display: flex;
		flex-direction: row;

		img {
			border-radius: 50%;
			max-width: 26px;
			max-height: 26px;
			object-fit: cover;
		}

		span, p {
			margin: 0;
		}
		span {
			@include fontSize12;
			color: var(--color-neutral-700);
			display: block;
			margin-top: 4px;
		}

		p {
			font-size: 15px;
			font-weight: $fontWeightMedium;
			color: var(--color-primary-800);
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		> div {
			display: flex;
			flex-direction: column;
			padding-left: 8px;
			justify-content: center;
		}
	}
}