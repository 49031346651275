@import "~variables";

.MobileMenu {
	max-width: 500px;
	width: 100%;
	background-color: #fff;
	position: absolute;
	z-index: 999;
	top: 0;
	transform: translateX(-500px);
	transition: 0.3s transform ease-out;
	padding: $spacing32;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.Main {
	.Head {
		display: flex;
		justify-content: space-between;

		.CloseBtn {
			&:hover {
				cursor: pointer;

				circle {
					fill: var(--color-neutral-300);
				}

				path {
					fill: var(--color-neutral-700);
				}
			}
		}
	}

	.Options {
		padding-left: $spacing32;

		.Option {
			@include fontSize20;
			color: var(--color-primary-800);
			margin-top: $spacing24;
			cursor: pointer;

			&:hover {
				color: var(--color-primary-500);
			}
		}
	}

	> .Options {
		margin-top: $spacing32;
		padding-left: 0;
	}
}

.Footer {
	display: flex;
	justify-content: space-between;

	.Logout {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.Options {
		display: flex;
	}

	.Option {
		cursor: pointer;
		&:hover {
			color: var(--color-primary-500);
		}
	}

	> .Options {
		.Option {
			margin-left: $spacing24;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	color: var(--color-neutral-700);
}