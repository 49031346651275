@import "~variables";

.Action {
	padding: 20px $spacing16;
	border: 1px solid #EBEFF5;

	@include small-screen {
		border-radius: $sizing8;
	}

	&.isExpanded {
		margin-bottom: $spacing8;
		padding: 20px $spacing16 $spacing32;

		h4 {
			@include fontSize20;
			font-weight: $fontWeightRegular;
			margin: 0;
		}
	}

	&.isCollapsed {
		&:hover {
			cursor: pointer;
			box-shadow: 0 1px 2px rgba(39, 54, 89, 0.15);
			padding-bottom: 28px;
			transition: 0.2s ease-out padding-bottom;
		}

		h3 {
			display: none;
		}

		h5 {
			@include fontSize18;
			color: var(--color-neutral-800);
			text-transform: none;
		}
	}
}

.Integration {
	display: flex;
	flex-direction: row;
	align-items: center;

	h5 {
		@include fontSize14;
		font-weight: 400;
		color: var(--color-neutral-600);
		text-transform: uppercase;
		margin: 0 0 $spacing4;
	}

	h3 {
		margin: 0;
		@include fontSize20;
		font-weight: $fontWeightRegular;
		color: var(--color-neutral-800);
	}

	img {
		max-width: 35px;
	}

	div {
		margin-left: $spacing16;
	}
}

.Header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	h3 {
		margin: 0;
		@include fontSize20;
		font-weight: $fontWeightRegular;
		color: var(--color-neutral-800);
	}
}

.Details {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	margin-top: $spacing24;

	.title {
		@include fontSize14;
		color: var(--color-neutral-600);
		margin: 0 0 $spacing8 0;
	}
}

.Status {
	margin: 0;
	display: inline-flex;
	@include fontSize14;
	color: var(--color-neutral-700);
	background-color: var(--color-neutral-200);
	border-radius: 18px;
	padding: $spacing4 $spacing12;
	font-weight: $fontWeightMedium;

	&.Done {
		background-color: var(--color-accent-green-100);
		color: var(--color-accent-green-700);
	}

	&.Failed {
		background-color: var(--color-accent-red-100);
		color: var(--color-accent-red-700);
	}

	&.Received {
		color: var(--color-accent-blue-800);
		background-color: var(--color-accent-blue-100);
	}

	.Processing, .Received {
		display: flex;
		align-items: center;

		span {
			margin-left: $sizing4;
		}
	}
}