@import "~variables";

.SignupPage {
  height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas: "form" "marketing";

  @include xsmall-screen {
    h1, h1 + p {
      text-align: center;
    }
  }

  @include large-screen {
    h1, h1 + p {
      text-align: left;
    }

    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: "form marketing";
  }


  @include large-screen {
    flex-direction: row;
  }

  h1 {
    font-size: 32px;
    font-weight: 300;
    margin: 8px 0;
    color: var(--color-neutral-900);

    @include large-screen {
      @include fontSize36;
    }
  }

  .highlight {
    color: var(--color-primary-600);
    text-decoration: none;
    font-weight: $fontWeightMedium;
  }

  > .Copyright {
    display: none;

    @include small-screen {
      display: block;
    }

    @include large-screen {
      display: none;
    }
  }
}

.Form {
  margin: $spacing24 $spacing16;
  grid-area: form;

  .Logo {
    display: flex;
    margin-bottom: $sizing8;

    @include xsmall-screen {
      justify-content: center;
      margin: auto;
      width: 100%;
    }

    @include large-screen {
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0 0 $sizing8;
      width: 100%;
    }
  }

  @include small-screen {
    margin: $spacing16;
  }


  @include large-screen {
    margin: 48px;

    p {
      @include fontSize16;
    }
  }

  .FormContent {
    margin-top: $spacing32;

    @include xsmall-screen {
      max-width: 500px;
      margin: $spacing32 auto auto;
    }

    @include small-screen {
      margin: $spacing16 auto auto;
    }

    @include large-screen {
      margin-top: 80px;
    }

    > p {
      margin-bottom: $spacing32;
    }

    .Copyright {
      @include small-screen {
        display: none;
      }

      @include large-screen {
        display: block;
      }
    }
  }
}

.LargeInput {
  input {
    height: 65px;
    width: 368px;
    border: 0;
    font-size: 16px;
    padding: 18px 16px 0;
    @include shadowCloseNeutral;

    @include xsmall-screen {
      width: 500px;
      height: 86px;
      font-size: 22px;
      padding: 22px 16px 0;
    }

    @include large-screen {
      height: 86px;
      //width: 550px;
      font-size: 22px;
      padding: 22px 16px 0;
    }
  }

  label {
    font-size: 15px;
    text-transform: none;
    font-weight: $fontWeightRegular;
    color: var(--color-neutral-700);
    left: $sizing12;
    top: $sizing12;

    @include xsmall-screen {
      @include fontSize16;
      left: $sizing16;
      top: $sizing16;
    }

    @include large-screen {
      @include fontSize16;
      left: $sizing16;
      top: $sizing16;
    }
  }

}

.ActionLinks {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing16;

  span, a {
    @include fontSize14;
  }

  span {
    color: var(--color-neutral-600);
  }

  @include xsmall-screen-only {
    max-width: 368px;
  }

  @include large-screen {
    max-width: 550px;
  }
}

.Terms {
  margin-top: $spacing32;

  p {
    font-size: 15px;
    color: var(--color-neutral-700);
  }

  @include large-screen {
    max-width: 500px;
    margin-top: $spacing24;
  }
}

.StepOneScreen {
  &.hide {
    visibility: hidden;
    z-index: -1;
  }
}
.StepTwoScreen {
  display: flex;
  flex-direction: column;
  position: absolute;
  visibility: hidden;
  z-index: -2;
  opacity: 0.5;
  top: 0;
  transform: translateY(10px);
  transition: 0.3s ease-out opacity, 0.3s ease-out transform;

  &.show {
    transform: translateY(0);
    z-index: 2;
    opacity: 1;
    visibility: visible;
  }

  .Status {
    background-color: white;
    display: flex;
    border-radius: $sizing8;
    align-items: center;
    padding: $spacing24;
    @include shadowCloseNeutral;
  }

  .Status > div:nth-of-type(2) {
    padding-left: $spacing16;
    box-sizing: border-box;

    h3 {
      @include fontSize18;
      margin: 0 0 $spacing8;
      font-weight: $fontWeightMedium;
      color: var(--color-neutral-900);
    }

    span {
      @include fontSize14;
      color: var(--color-neutral-700);
    }
  }
}

.EmailProviderLinks {
  margin-top: $spacing16;
  display: flex;

  > div {
    padding: $spacing8 $spacing12;
    margin-right: $spacing8;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    @include shadowCloseNeutral;

    p {
      margin: 0 0 0 $spacing8;
    }

    a {
      text-decoration: none;
      color: var(--color-neutral-800);
    }

    a div {
      display: flex;
    }
  }
}

// todo extract?
.LoadingScreen {
  background-color: transparent;
  opacity: 0;
  top: -10px;
  left: -10px;
  position: absolute;
  z-index: -1;
  width: 110%;
  height: 110%;
  transition: 0.3s ease opacity;

  img {
    position: absolute;
    top: 0;
    left: 25%;
  }

  div {
    background-color: var(--color-primary-100);
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease opacity;
  }

  &.show {
    z-index: 1;
    opacity: 1;

    div {
      opacity: 0.7;
    }
  }
}

.ResendLink {
  display: flex;
  align-items: center;
  margin-top: $spacing32;

  p {
    font-size: 15px;
    color: var(--color-neutral-700);
    margin: 0;

    span {
      text-decoration: underline;
    }
  }

  > div {
    font-size: 15px;
    padding: 4px $spacing8;
    margin-left: $spacing16;
    background-color: white;
    border-radius: 6px;
    color: var(--color-neutral-700);
    font-weight: $fontWeightMedium;
    @include shadowCloseNeutral;

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 7px rgba(39, 54, 89, 0.15);
    }
  }
}

.Copyright {
  margin-top: 85px;

  @include small-screen {
    margin-top: $spacing16;
    text-align: center;
  }

  @include large-screen {
    margin-top: 85px;
    text-align: left;
  }

  p {
    @include fontSize14;
    color: var(--color-neutral-700);
  }

  @include large-screen {
    margin-top: 150px;
  }
}

.MarketingPanelBackground {
  background-color: white;
  display: none;
  width: 100%;
  grid-area: marketing;

  @include small-screen {
    margin-top: $spacing16;
    display: block;
  }

  @include large-screen {
    margin-top: 0;
  }
}

.MarketingPanel {
  display: none;
  max-width: 850px;
  margin: auto;

  @include small-screen {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto auto;
    grid-template-areas: "title image"
                          "benefits image";
    padding: $spacing24;
    box-sizing: border-box;
  }

  @include medium-screen {
    padding: $spacing32;
  }

  @include medium-screen {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto auto;
    grid-template-areas: "title image"
    "benefits image";
    padding: $spacing24;
    box-sizing: border-box;
  }

  @include large-screen {
    grid-auto-rows: auto auto auto;
    grid-template-areas: "title" "image" "benefits";
    justify-content: center;
    padding: 48px 0;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    max-width: 450px;
    text-align: center;
    grid-area: title;

    @include small-screen {
      max-width: 400px;
      text-align: left;
    }

    @include large-screen {
      text-align: center;
    }
  }

  .Graphic {
    margin-top: $spacing24;
    grid-area: image;

    @include small-screen {
      align-self: center;
    }

    @include large-screen {
      justify-self: center;
    }
  }
}

.Benefits {
  margin-top: $spacing48;
  padding-left: $spacing24;
  grid-area: benefits;

  @include large-screen {
    justify-self: center;
    padding-left: 0;
  }

  > div {
    max-width: 330px;
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing24;
  }

  > div > div {
    margin: 0 0 0 $spacing12;
  }

  svg {
    min-width: 25px;
  }

  h3 {
    margin: 0 0 $spacing12 0;
    font-weight: $fontWeightRegular;
    color: var(--color-neutral-900);

    @include large-screen {
      font-weight: $fontWeightSemibold;
    }
  }

  p {
    display: none;
    margin: 0;
    font-size: 15px;
    line-height: 20px;

    @include large-screen {
      display: block;
    }
  }
}

