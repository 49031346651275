@import "~variables";

.CloseAccount {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Header {
	margin-top: $spacing24;
	max-width: 600px;
	width: 100%;

	h1 {
		@include fontSize24;
		font-weight: $fontWeightRegular;
		margin: 0 0 $spacing16;
	}
}

.Question {
	margin-bottom: $spacing32;

	&:last-of-type {
		margin-bottom: 0;
	}

	p {
		font-weight: $fontWeightSemibold;
		margin: 0;
	}

	span {
		@include fontSize12;
		color: var(--color-neutral-700);
	}
}

.ExperienceOption {
	margin-bottom: $spacing16;
}

.Ratings {
	display: flex;
	margin-top: $spacing8;

	> div {
		@include fontSize18;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border: 1px solid var(--color-neutral-300);
		border-radius: $sizing4;
		margin-right: 6px;

		&:hover {
			cursor: pointer;
			border-color: var(--color-primary-300);
		}
	}

	.Selected {
		background-color: var(--color-primary-500);
		border-color: var(--color-primary-500);
		color: var(--color-primary-100);
	}
}

.RetentionPeriod {
	> div:first-of-type {
		display: flex;
		flex-wrap: wrap;

		div {
			display: inline-block;
			position: relative;
			top: 4px;
		}
	}
}

.RetentionOptions {
	margin-top: $spacing12;
	margin-bottom: $spacing24;
}

.FixedRetentionPeriod {
	span {
		@include fontSize12;
		color: var(--color-neutral-700);
		margin-left: 26px;
	}
}

.Actions {
	display: flex;
	align-items: center;

	span {
		@include fontSize14;
		color: var(--color-neutral-700);
		margin-left: $spacing12;

		&:hover {
			cursor: pointer;
			color: var(--color-primary-500);
		}
	}
}