@import "~variables";

.ResetPassword {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;

	.Logo {
		margin-top: $spacing48;
		margin-bottom: $spacing24;
	}

	.Header {
		text-align: center;
		width: 400px;

		h1 {
			@include fontSize24;
			margin: 0 0 $spacing8 0;
		}

		p {
			margin: 0;
			font-size: 15px;
			color: var(--color-neutral-600);
		}
	}
}