$sizing4: 0.25rem;
$sizing8: 0.5rem;
$sizing12: 0.75rem;
$sizing16: 1rem;
$sizing24: 1.5rem;
$sizing32: 2rem;
$sizing48: 3rem;
$sizing64: 4rem;
$sizing96: 6rem;
$sizing128: 8rem;
$sizing192: 12rem;
$sizing256: 16rem;
$sizing384: 24rem;
$sizing512: 32rem;
$sizing640: 40rem;
$sizing768: 48rem;
