* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  color: #44506E;
  background-color: var(--color-primary-100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-primary-100: #F0F3FC;
  --color-primary-200: #D9E4FC;
  --color-primary-300: #9CB7F7;
  --color-primary-400: #5583ED;
  --color-primary-500: #265DDE;
  --color-primary-600: #2253C7;
  --color-primary-700: #1F49AB;
  --color-primary-800: #1B3B87;
  --color-primary-900: #132859;

  --color-neutral-100: #F2F5FA;
  --color-neutral-200: #e9ebf2;
  --color-neutral-300: #CACED9;
  --color-neutral-400: #A2A9BA;
  --color-neutral-500: #98A0B3;
  --color-neutral-600: #8892A8;
  --color-neutral-700: #636E87;
  --color-neutral-800: #44506e;
  --color-neutral-900: #283759;

  --color-accent-blue-100: #E3F6FC;
  --color-accent-blue-200: #CDEDF7;
  --color-accent-blue-300: #A0DEF2;
  --color-accent-blue-400: #6BCDED;
  --color-accent-blue-500: #2DBAE0;
  --color-accent-blue-600: #229EC7;
  --color-accent-blue-700: #12668A;
  --color-accent-blue-800: #0F5775;
  --color-accent-blue-900: #0E465C;

  --color-accent-green-100: #D9F5E8;
  --color-accent-green-200: #B9E5D6;
  --color-accent-green-300: #92D6BF;
  --color-accent-green-400: #54B997;
  --color-accent-green-500: #2EB386;
  --color-accent-green-600: #2A9672;
  --color-accent-green-700: #2B8063;
  --color-accent-green-800: #24624D;
  --color-accent-green-900: #204F40;

  --color-accent-red-100: #F7DEDE;
  --color-accent-red-300: #F7BBBB;
  --color-accent-red-400: #EF8888;
  --color-accent-red-500: #D95656;
  --color-accent-red-700: #C43535;
  --color-accent-red-800: #AC2323;
  --color-accent-red-900: #640F0F;

  --color-accent-yellow-100: #FAF0D7;
  --color-accent-yellow-300: #F8DEA1;
  --color-accent-yellow-500: #F5CA64;
  --color-accent-yellow-700: #E5932F;
  --color-accent-yellow-900: #BF6D0A;
}

@media print {
  body {
    background-color: white;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--color-primary-800);
  text-decoration: none;
}


h1 {
  font-size: 2.25rem;
  line-height: 2.6875rem;
  margin-top: 0;
  font-weight: 400;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.8125rem;
  margin-top: 0;
  font-weight: 400;
}

.FormRow {
  display: grid;
  grid-template-columns: repeat(4, 120px);
  grid-column-gap: 24px;
  margin-bottom: 16px;
  align-items: start;
}

.FormRowMobile {
  display: grid;
  grid-template-columns: repeat(2, 120px);
  grid-column-gap: 24px;
  margin-bottom: 16px;
  align-items: start;
}

.FormRow:only-child {
  margin-bottom: 0;
}

.Popover {
  box-sizing: border-box;
  padding: 12px;
  background-color: white;
  box-shadow: 0 1px 4px rgba(39, 54, 89, 0.15);
  border-radius: 6px;
}

.fadeIn {
  animation-name: animateFadeIn;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

.fadeOut {
  animation-duration: 0.15s;
  animation-name: animatefadeOut;
  animation-fill-mode: forwards;
}

@keyframes animateFadeIn {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animatefadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(5px);
  }
}

.slideFade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.slideFade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms cubic-bezier(0.03, 0.27, 0.32, 1),
  transform 300ms cubic-bezier(0.03, 0.27, 0.32, 1);
}

.placeholderBlock {
  background-color: var(--color-neutral-300);
  border-radius: 6px;
  padding-bottom: 6px;
}