@import "~variables";

.ProfileImageEdit {
  display: flex;
  align-items: center;
  justify-content: center;

  &.hasNoImage {
    .ChangeImageLink {
      visibility: hidden;
      position: absolute;
      transform: translateY(10px);
    }

    .UploadedImage {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.isUploading {
    .Uploading {
      visibility: visible;
      opacity: 1;
    }

    .UploadControls {
      visibility: hidden;
      position: absolute;
    }

    .DefaultImage {
      visibility: hidden;
      opacity: 0;
    }

    .UploadedImage {
      visibility: hidden;
      opacity: 0;
    }

    .ChangeImageLink {
      visibility: hidden;
      position: absolute;
    }
  }

  &.hasImage {
    flex-direction: column;

    .DefaultImage {
      visibility: hidden;
    }

    .UploadControls {
      visibility: hidden;
      position: absolute;
    }
  }
}

.UploadControls {
  margin-left: $spacing16;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    @include fontSize12;
    color: var(--color-neutral-700);
    margin-top: $spacing8;
  }
}

.UploadBtn {

}

.Image {
  position: relative;
  width: 75px;
  height: 75px;
}

.DefaultImage {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-out visibility, 0.3s ease-out opacity;
}

.UploadedImage {
  position: absolute;
  width: 75px;
  height: 75px;
  transition: 0.3s ease-out visibility, 0.3s ease-out opacity;

  img {
    max-width: 75px;
    height: 75px;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .RemoveBtn {
    background-color: white;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 0;

    &:hover {
      cursor: pointer;

      svg {
        circle {
          fill: var(--color-accent-red-300);
        }

        path {
          fill: var(--color-accent-red-500);
        }
      }
    }
  }
}

.ChangeImageLink {
  p {
    margin: $spacing12 0 0;
    text-decoration: underline;
    color: var(--color-neutral-700);
    @include fontSize14;
  }

  &:hover {
    cursor: pointer;
    color: var(--color-neutral-800);
  }
}

.Uploading {
  visibility: hidden;
  opacity: 0;
  width: 75px;
  height: 75px;
  background-color: var(--color-primary-200);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-out visibility, 0.3s ease-out opacity;
}