
@mixin shadowClosePrimary() {
  box-shadow: 0 1px 4px rgba(18, 39, 89, 0.15);
}

@mixin shadowDistantPrimary() {
  box-shadow: 0 8px 16px rgba(18, 39, 89, 0.2);
}

@mixin shadowCloseNeutral() {
  box-shadow: 0 1px 4px rgba(39, 54, 89, 0.15);
}

@mixin shadowDistantNeutral() {
  box-shadow: 0 8px 16px rgba(39, 54, 89, 0.2);
}