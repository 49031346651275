@import "~variables";

.JobLogs {
	margin-top: $spacing24;
}

.WorkflowSelection {
	border-top: 1px solid var(--color-neutral-200);
	margin-top: $spacing16;
	padding-top: $spacing16;
}

.WorkflowDropdown {
	width: 350px;

	> div:first-of-type {
		border-radius: 25px;
		border-color: var(--color-neutral-200);
		height: 50px;
		@include fontSize20;

		> div {
			margin-left: $spacing12;
		}
	}
}

.Header {
	position: relative;
	margin-top: $spacing24;

	h2 {
		@include fontSize20;
		margin: 0 0 $spacing16 0;
	}

	> div:first-of-type {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;

		span {
			display: block;
			margin-top: $spacing8;
			@include fontSize14;
			color: var(--color-neutral-600);
			font-weight: $fontWeightMedium;
		}
	}

	> div:nth-of-type(2) {
		display: flex;
	}
}

.LogEventsTable {
	margin-top: $spacing24;

	.TableHeader {
		@include fontSize14;
		color: var(--color-neutral-600);
		text-transform: uppercase;
		font-weight: $fontWeightSemibold;
		margin-bottom: $spacing8;

		.Indicator {
			background-color: white;
		}

		> div {
			padding: 0 $spacing12;
			box-sizing: border-box;
		}
	}
	.TableHeader, .Row {
		display: flex;
	}
	.Indicator {
		min-width: 44px;
		background-color: var(--color-primary-100);
		align-items: center;

		&.Green {
			background-color: var(--color-accent-green-100);
		}

		&.Red {
			background-color: var(--color-accent-red-100);
		}

		&.Yellow {
			background-color: var(--color-accent-yellow-100);
		}

		&.Subworkflow {
			background-color: var(--color-primary-200);
		}
	}
	.Event {
		min-width: 280px;
	}

	.Event, .Details {
		p {
			margin: $spacing8 0 0 0;
		}

		span {
			@include fontSize14;
			color: var(--color-neutral-700);
		}

		> div {
			display: flex;
			align-items: center;
			margin: $spacing8 0 0 0;

			p {
				margin: 0 0 0 6px;
			}
		}
	}

	.Details {
		min-width: 250px;
	}

	.Date {
		min-width: 180px;
		width: 100%;
		text-align: right;

		p {
			margin: 0 0 $spacing8 0;
		}

		span {
			@include fontSize14;
			color: var(--color-neutral-700);
		}
	}

	.TableRows {
		border-radius: $sizing8;
		border: 1px solid var(--color-neutral-300);
	}
	.Row {
		height: 80px;
		border-bottom: 1px solid var(--color-neutral-200);

		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: $spacing12;
			box-sizing: border-box;
			border-left: 1px solid var(--color-neutral-100);

			&:first-of-type {
				border-left: 0;
			}
		}

		&:last-of-type {
			border-bottom: 0;

			.Indicator {
				border-bottom-left-radius: $sizing8;
			}
		}

		&:first-of-type {
			.Indicator {
				border-top-left-radius: $sizing8;
			}
		}
	}
}