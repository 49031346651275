@import "~variables";

.LoginPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.3fr 1fr;
  grid-template-areas: "login" "signup";
  min-height: 100vh;
  background-color: var(--color-primary-100);
}

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacing32;
  margin-top: $spacing32;

  span {
    margin-left: $spacing12;
  }
}

.ErrorMessage {
  width: 100%;
}

.Inputs {
  @include shadowClosePrimary;
  border-radius: 6px;
}

.LargeInput {

  input {
    height: 87px;
    @include fontSize20;
    padding: $spacing16 $spacing16 0;
    filter: none;
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: var(--color-neutral-800);
  }

  label {
    left: $spacing16;
    top: $spacing16;
  }
}
.EmailField {
  @extend .LargeInput;

  input {
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--color-primary-200);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      border-color: var(--color-primary-200);
    }
  }
}
.PasswordField {
  @extend .LargeInput;

  input {
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 0;
    border-bottom: 1px solid;
    border-color: var(--color-primary-200);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:focus {
      border-color: var(--color-primary-200);
    }
  }
}

.LoginForm {
  grid-area: login;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  justify-content: center;
  margin: auto auto $spacing48;
}

.Options {
  margin-top: $spacing16;
  margin-bottom: $spacing24;
  display: flex;
  justify-content: space-between;

  .ForgotPassword {
    span {
      @include fontSize14;
      color: var(--color-neutral-700);
    }
  }
}

.Signup {
  grid-area: signup;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Legal {
  margin-top: $spacing64;
  @include fontSize14;
}