@import "~variables";

.CollapsedElement {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h4 {
		margin: 0;
		font-weight: $fontWeightRegular;
		@include fontSize18;
	}
}

.ExpandedElement {
	visibility: hidden;
	transform: translateY(10px);
	opacity: 0;
	transition: 0.3s ease-out visibility, 0.2s ease-out transform, 0.3s ease-out opacity;
}

.ExpandBtn {
	position: absolute;
	display: flex;
	padding: 2px 28px;
	border-radius: $sizing8;
	bottom: -42px;
	left: 50%;
	transform: translateX(-50%);
	border: 1px solid var(--color-primary-200);
	background-color: var(--color-primary-100);

	&:hover {
		cursor: pointer;
		background-color: var(--color-primary-200);
	}

	p {
		margin: 0 $spacing4 0 0;
		@include fontSize12;
	}
}