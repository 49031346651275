@import "~variables";

.Comment {
	padding: 20px $spacing16;
	border-radius: $sizing8;
	border: 1px solid #F2E9D0;
	background-color: #FCF7EB;

	.Details {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		img {
			border-radius: 50%;
			max-width: 30px;
			max-height: 30px;
			object-fit: cover;
		}

		span, p {
			color: var(--color-neutral-800);
		}
		span {
			@include fontSize12;
			margin-right: $spacing16;
		}

		p {
			margin: 0 0 0 $spacing12;
		}

		> div {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
}

.Message {
	margin-top: $spacing16;
}

.OptionsMenu {
	p {
		cursor: pointer;
		margin: 0;
	}
}