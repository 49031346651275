@import "~variables";

.PayloadBuilder {
	display: grid;
	grid-template-columns: 610px 500px;
	height: 100%;
	box-sizing: border-box;
	justify-content: center;
}

.Page {
	background-color: white;
	box-sizing: border-box;
}

.Content {
	padding: 0 $spacing24;
	box-sizing: border-box;

	h2 {
		@include fontSize18;
		margin: 0 0 $spacing8 0;
		font-weight: $fontWeightRegular;

		+ span {
			@include fontSize14;
			color: var(--color-neutral-600);
		}
	}

	.PayloadOptions {
		display: flex;
		flex-wrap: wrap;
		margin-top: $spacing16;
	}

	.PayloadOption {
		@include fontSize14;
		background-color: var(--color-neutral-200);
		padding: $sizing4 $sizing12;
		letter-spacing: 0.5px;
		margin-right: $spacing8;
		margin-bottom: $sizing8;
		border-radius: $sizing12;
		position: relative;

		svg {
			margin-right: $sizing4;
		}

		&:hover {
			cursor: pointer;
			background-color: var(--color-primary-100);
			color: var(--color-primary-800);
		}

		&.Selected {
			background-color: var(--color-primary-200);
			color: var(--color-primary-800);

			&:hover {
				cursor: pointer;
				background-color: var(--color-primary-200) !important;
			}
		}
	}

	.CustomProperties {
		h3 {
			@include fontSize16;
			font-weight: $fontWeightRegular;
			margin: $spacing16 0 $spacing8 0;
		}

		> div {
			> div {
				> div:nth-of-type(2) {
					input {
						width: 227px;
					}
				}
			}
		}
	}
}

.PayloadPreview {
	background-color: #132859;
	border-left: 1px solid var(--color-neutral-200);

	.Header {
		background-color: var(--color-primary-900);
		padding: $spacing24 $spacing24 0;

		h1 {
			color: white;

			font-weight: $fontWeightSemibold;
			@include fontSize20;
		}
	}

	.Code {
		padding: 0 $spacing12 $spacing12;
	}

	h5 {
		padding-left: $spacing12;
		padding-top: $spacing8;
		padding-bottom: $spacing8;
		font-weight: $fontWeightSemibold;
		color: var(--color-primary-400);
		@include fontSize12;
	}
}