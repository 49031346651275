$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;
$fontWeightBold: 700;

// - metaphorical names for sizes (xs, xxl, etc) don't scale well.
// - providing a literal pixel value provides a concrete list of available options.
// - using a mixin allows us to return the REM value, but still reference the human-friendlier pixel value.
@mixin fontSize12() {
  font-size: 0.75rem;
  line-height: 0.875rem;
}

@mixin fontSize14() {
  font-size: 0.875rem;
  line-height: 1rem;
}

@mixin fontSize16() {
  font-size: 1rem;
  line-height: 1.1875rem;
}

@mixin fontSize18() {
  font-size: 1.125rem;
  line-height: 1.3125rem;
}

@mixin fontSize20() {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@mixin fontSize24() {
  font-size: 1.5rem;
  line-height: 1.8125rem;
}

@mixin fontSize30() {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@mixin fontSize36() {
  font-size: 2.25rem;
  line-height: 2.6875rem;
}

@mixin fontSize48() {
  font-size: 3rem;
  line-height: 3.625rem;
}

