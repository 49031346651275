@import "~variables";

.ActivationLayout {
  display: grid;
  grid-template-columns: 235px auto;
  grid-template-rows: auto auto;
  grid-column-gap: $spacing48;
  grid-template-areas: "header header" "main main";

  @include medium-screen {
    grid-template-areas: "header header" "menu main";
  }

  @include large-screen {
    grid-template-columns: 275px auto;
  }
}


.Header {
  grid-area: header;
  display: flex;
  align-items: center;
  padding: $sizing12 $sizing24;
  border-bottom: 1px solid var(--color-neutral-200);
  margin-bottom: $spacing32;

  p {
    margin: 0 0 0 $spacing12;
    color: var(--color-neutral-700);
  }
}


.Menu {
  display: none;
  grid-area: menu;
  padding-left: $spacing24;

  @include medium-screen {
    display: block;
  }
}

.MenuItem {
  margin: 0;
  font-size: 15px;
  color: var(--color-neutral-700);
  padding: $spacing8 0 $spacing8 $spacing24;
  border-left: 2px solid var(--color-neutral-300);
  box-sizing: border-box;

  p {
    margin: 0;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &.active {
    border-left-color: var(--color-primary-400);

    p {
      color: var(--color-primary-400);
    }
  }
}

.Main {
  grid-area: main;
}

.SpinnerBackdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  @include shadowCloseNeutral;
}

.InvalidTokenPanel {
  .Card {
    padding: $spacing24;
    width: 100%;
    max-width: 500px;

    h2 {
      margin: 0;
    }

    p {
      margin: $spacing16 0 $spacing24 0;
    }
  }
}

.CreateProfile, .SetupAccount, .InviteTeam {
  display: flex;
  justify-content: center;

  @include medium-screen {
    justify-content: normal;
  }
}

.UserForm {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.SectionHeading {
  h2, p {
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    color: var(--color-neutral-600);
    font-size: 15px;
  }
}

.Preferences {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 16px;
  margin-top: $spacing32;
}

.Preference {
  border: 1px solid var(--color-neutral-300);
  border-radius: $sizing8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $spacing32 $spacing12;
  box-sizing: border-box;

  p {
    margin: $spacing16 0 0;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    border-color: var(--color-primary-400);

    p {
      color: var(--color-primary-500);
    }
  }

  &.selected {
    background-color: var(--color-primary-100);
    border-color: var(--color-primary-400);

    p {
      color: var(--color-primary-700);
      font-weight: $fontWeightSemibold;
    }
  }
}

.InviteTeam {
  .SectionHeading {
    h2, p {
      text-align: left;
    }
  }
}

.InviteInput {
  margin-top: $spacing12;
}

.AddInvite {
  display: flex;
  align-items: center;

  p {
    margin-left: $spacing8;
  }

  svg {
    fill: var(--color-neutral-600);
  }

  &:hover {
    cursor: pointer;
    p {
      color: var(--color-primary-500);
    }

    svg {
      fill: var(--color-primary-500);
    }
  }
}