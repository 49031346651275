@import "~variables";

.ResponseBuilder {
	display: grid;
	grid-template-columns: 610px 550px;
	height: 100%;
	box-sizing: border-box;
	justify-content: center;
}

.Page {
	background-color: white;
	box-sizing: border-box;
}

.Main {
	padding: 0 $spacing24;

	&.Hide {
		visibility: hidden;
		position: absolute;
		z-index: -1;
	}
}

.ResponseOptions {
	display: flex;
	align-items: center;

	.CaptureResponseBtn {
		margin-right: $spacing12;
		cursor: pointer;
	}
}

.SelectedResponseInputValue {
	display: flex;
	align-items: center;

	p {
		font-weight: $fontWeightMedium;
		margin: 0;
	}

	span {
		font-size: 13px;
		color: var(--color-neutral-600);
		margin-left: 12px;
		margin-right: 12px;
	}
}

.Trash {
	cursor: pointer;
}

.Variables, .PrefillFields  {
	margin-top: $spacing24;

	.Title {
		background-color: var(--color-primary-100);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 14px 6px;
		border-top: 2px solid var(--color-primary-200);
		border-left: 2px solid var(--color-primary-200);
		border-right: 2px solid var(--color-primary-200);
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		margin-left: 10px;
		margin-right: 10px;

		h2 {
			@include fontSize14;
			font-weight: $fontWeightMedium;
			margin: 0;
			line-height: 18px;
			text-transform: uppercase;
		}

		span {
			@include fontSize12;
			color: var(--color-neutral-700);

			&:hover {
				cursor: pointer;
				color: var(--color-primary-800);
			}

			&:first-of-type {
				margin-right: 8px;
				padding-right: 8px;
			}
		}
	}
}

.Variable {
	border: 1px solid var(--color-neutral-300);
	border-radius: 8px;
	padding: 12px;
	margin-bottom: $spacing12;

	.EditBtn {
		cursor: pointer;
	}

	.EditScreen {
		> div {
			display: flex;
			align-items: center;

			&:first-of-type {
				margin-bottom: 8px;
			}
		}

		input {
			height: 34px;
			line-height: 38px;
			width: 250px;
			border: 1px solid var(--color-neutral-300);
			border-radius: 8px;
		}

		p {
			margin: 0;
			width: 50px;
		}
	}

	.Details {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			font-size: 15px;
			font-style: italic;
			color: var(--color-neutral-700);
			overflow-wrap: anywhere;
		}

		label {
			padding-bottom: 0;
		}

		> div {
			display: flex;
			align-items: center;
			margin-right: $spacing12;
		}
	}

	.VariableType {
		margin-top: 12px;
		max-width: 200px;

		> div:first-of-type {
			height: 34px;
			font-size: 14px;

			> div:nth-of-type(2) {
				background-color: white;
			}
		}
	}
}

.FieldsList {
	border: 1px solid var(--color-neutral-300);
	padding: 16px;
	border-radius: 8px;

	p {
		margin: 0 0 12px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.RawResponsePreview {
	background-color: #132859;
	border-left: 1px solid var(--color-neutral-200);
	width: 100%;

	> div:first-of-type {
		padding: $spacing16 $spacing16 0;
		box-sizing: border-box;
		background-color: var(--color-primary-900);

		h1 {
			color: white;
			font-weight: $fontWeightSemibold;
			@include fontSize20;
		}
	}

	.Code {
		padding: 0 $spacing12 $spacing12;
	}

	h5 {
		padding-left: $spacing12;
		padding-top: $spacing8;
		padding-bottom: $spacing8;
		font-weight: $fontWeightSemibold;
		color: var(--color-primary-400);
		@include fontSize12;
	}
}

.UncapturedResponse {
	visibility: hidden;
	height: 0;
	position: absolute;

	border: 1px solid var(--color-neutral-300);
	border-radius: 8px;
	margin: 0 24px;
	box-sizing: border-box;

	&.Show {
		visibility: visible;
		height: auto;
		position: static;
	}

	svg {
		padding: 16px 0 16px 16px;
	}

	h3 {
		margin: 0;
		background-color: var(--color-accent-green-100);
		padding: 8px 0 8px 16px;
		width: 375px;
		font-weight: $fontWeightMedium;
		font-size: 18px;
	}

	> div:first-of-type {
		padding: 0 16px 16px;
	}

	span {
		font-size: 14px;
		color: var(--color-neutral-700);
	}
}
