@import "~variables";

.UserPage {
	box-sizing: border-box;
	padding: $spacing12 $spacing16 0 $spacing16;
	width: 100%;
	height: 100%;
	display: flex;

	h2 {
		@include fontSize20;
		font-weight: $fontWeightRegular;
		color: var(--color-neutral-800);
	}
}

.EditUser, .InviteUser {
	> div:first-of-type {
		max-width: 450px;
		width: 100%;
	}
}

.EditUser {
	.Actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.Delete {
		position: relative;
		&:hover {
			cursor: pointer;

			circle {
				stroke: var(--color-primary-300);
			}
			path {
				fill: var(--color-primary-400);
			}
		}
	}
}

.WorkflowAccess {
	h3 {
		@include fontSize16;
		font-weight: $fontWeightMedium;
		margin-top: $spacing32;
		margin-bottom: $spacing8;
	}

	h3 + span {
		@include fontSize14;
		color: var(--color-neutral-600);
	}
}

.AccessTypes {
	display: flex;
	margin-top: $spacing12;
	margin-bottom: $spacing16;
}

.AccessType {
	border: 1px solid var(--color-neutral-300);
	border-radius: $sizing8;
	width: 218px;
	height: 106px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-right: $spacing16;

	p {
		margin: $sizing8 0 0;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&:hover {
		cursor: pointer;
		border-color: var(--color-primary-300);
	}

	&.active {
		background-color: var(--color-primary-100);
		border-color: var(--color-primary-500);
	}
}
