@import "~variables";

.DetailsModal {
	min-height: 400px;
	padding: $spacing24;
	display: flex;
}

.Content {
	border: 1px solid var(--color-neutral-200);
	border-radius: $spacing4;
	display: flex;
	min-height: 100%;
	width: 100%;
	position: relative;

	.Resend {
		position: absolute;
		right: 24px;
		top: 16px;

		svg {
			margin-right: $spacing8;
		}
	}
}

.AttemptsMenu {
	min-width: 225px;
	border-right: 1px solid var(--color-neutral-200);
	box-sizing: border-box;

	h5 {
		padding: $spacing12 $spacing12 0;
		color: var(--color-primary-800);
		font-weight: $fontWeightSemibold;
		@include fontSize12;
	}
}

.LogRecordAttemptMenuItem {
	display: flex;
	justify-content: space-between;
	height: 49px;
	align-items: center;
	border-bottom: 1px solid var(--color-neutral-100);
	padding: 0 $spacing12 0 $spacing16;
	position: relative;

	span {
		@include fontSize14;
		color: var(--color-neutral-700);
	}

	&:hover {
		cursor: pointer;

		&::before {
			content: '';
			height: 49px;
			position: absolute;
			width: 5px;
			background-color: var(--color-primary-300);
			left: 0;
			top: 0;
		}
	}

	&.Selected {
		background-color: var(--color-primary-100);

		&::before {
			content: '';
			height: 49px;
			position: absolute;
			width: 5px;
			background-color: var(--color-primary-400);
			left: 0;
			top: 0;
		}

	}
}

.LogRecord {
	box-sizing: border-box;
	padding: $spacing24;

	.Header {
		display: flex;
		align-items: center;
		margin-bottom: $spacing24;
	}

	.Icon {
		margin-right: $spacing16;
	}

	.Title {
		display: flex;
		flex-direction: column;

		span {
			@include fontSize14;
			text-transform: uppercase;
			color: var(--color-neutral-600);
		}

		h1 {
			@include fontSize20;
			font-weight: $fontWeightRegular;
			margin: $spacing8 0 0 0;
		}
	}



	.Details {
		display: flex;
		flex-direction: column;

		.Subtle {
			color: var(--color-neutral-700);
		}
		.Inline {
			display: flex;
			margin-bottom: $spacing16;

			p {
				margin: 0;

			}

			p:first-of-type {
				width: 150px;
				color: var(--color-neutral-700);
				font-size: 15px;
			}
		}

		.Reason {
			margin-top: $spacing24;
			p {
				margin: 0;
			}

			p:first-of-type {
				font-size: 15px;
				color: var(--color-neutral-700);
				margin: 0 0 $spacing12 0;
			}
		}

		.NextAttempt {
			margin-top: $spacing32;
			background-color: var(--color-neutral-100);
			display: flex;
			align-items: center;
			box-sizing: border-box;

			p {
				@include fontSize14;
				margin: 0;
				padding: $spacing16;
			}

			span {
				cursor: pointer;
				margin-left: $spacing8;
				padding: $spacing16;
				border-left: 1px solid var(--color-neutral-200);
				@include fontSize14;
				color: var(--color-primary-700);
				font-weight: $fontWeightMedium;

				&:hover {
					color: var(--color-primary-900);
				}
			}
		}
	}
}