@import "~variables";

.History {
	//display: flex;
	//flex-direction: column;
	//align-items: center;
	padding-top: $spacing12;
	width: 100%;
	max-width: 682px;
	box-sizing: border-box;
	margin: auto;

	@include small-screen {
		padding-top: $spacing32;
	}

	@include medium-screen {
		align-items: flex-start;
		padding: $spacing12 $spacing12 0 $spacing12;
		margin: 0;
	}

	@include large-screen {
		//padding: $spacing32 0 0 $spacing16;
	}

}

.Head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 $spacing16;
	box-sizing: border-box;

	@include small-screen {
		padding: 0;
	}

	@include medium-screen {
		padding: 0 $spacing16;
	}

	@include large-screen {
		padding: 0;
	}

	p {
		@include fontSize14;
		color: var(--color-neutral-700);
		text-transform: uppercase;
		letter-spacing: 0.8px;
		margin: 0;

		@include small-screen {
			margin-left: 0;
		}
	}
}

.ExpandCollapseButton {
	cursor: pointer;
	width: 13px;
	height: 24px;
	overflow: visible;

	g {
		transition: 0.25s ease-out transform;
	}

	&.Collapsed {
		&:hover {

			g:first-of-type {
				transform: translate(13px, 0) rotate(90deg);
			}
			g:nth-of-type(2) {
				transform: translate(0, 22px) rotate(-90deg);
			}
		}
	}

	&.Collapsed {
		g:first-of-type {
			transform: translate(13px, 2px) rotate(90deg);

		}
		g:nth-of-type(2) {
			transform: translate(0, 20px) rotate(-90deg);
		}
	}

	&.Expanded {

		g:first-of-type {
			transform: translate(0, 8px) rotate(-90deg);
		}
		g:nth-of-type(2) {
			transform: translate(13.5px, 11px) rotate(90deg);
		}
	}

	&:hover {
		path {
			fill: var(--color-primary-400);
		}
	}
}

.HistoryEvents {
	width: 100%;
	overflow: hidden;
}

.HistoryEvent {
	background-color: white;
	margin-bottom: $spacing4;

	transition: 0.5s cubic-bezier(0.35, 0.35, 0.47, 0.98) all;
	z-index: -1;
	transform: translateY(-100%);
	position: absolute;
	visibility: hidden;

	&.Show {
		transform: translateY(0);
		position: relative;
		z-index: 1;
		visibility: visible;
	}
	&.Hide {
		transform: translateY(-100%);
	}

	@include small-screen {
		margin-bottom: $spacing12;
	}
}

.WorkflowCompletedMarker {
	p {
		@include fontSize12;
		color: var(--color-neutral-600);
		font-weight: $fontWeightMedium;
		text-transform: uppercase;
		letter-spacing: 0.8px;
		padding: $spacing16 0 0 $spacing8;
	}
}