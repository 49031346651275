@import "~variables";

.ExternalEndpointsList {
	margin: $spacing12;
	box-sizing: border-box;
	height: 100%;
	padding: $spacing16;

	.Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid var(--color-neutral-200);
		padding: $spacing16;
		box-sizing: border-box;
		border-radius: $spacing4;

		> div:first-of-type {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;
		}

		h1 {
			@include fontSize20;
			color: var(--color-neutral-900);
			font-weight: $fontWeightMedium;
			margin: 0 0 $spacing16 0;
		}

		p {
			margin: 0;
			@include fontSize14;
			color: var(--color-neutral-700);
		}
	}

	.Table {
		margin-top: $spacing16;
	}
}

.Header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $spacing24;

	> div:first-of-type {
		display: flex;
		align-items: center;

		svg {
			margin-right: $spacing12;
		}
	}

	.BackBtn {
		cursor: pointer;
	}

	h1 {
		@include fontSize20;
		color: var(--color-neutral-900);
		font-weight: $fontWeightRegular;
		margin: 0;
	}

	p {
		margin: 0;
		@include fontSize14;
		color: var(--color-neutral-700);
	}
}


.EndpointSetup {
	display: grid;
	grid-template-columns: 650px;
	justify-content: center;
	height: 100%;
	box-sizing: border-box;

	.Actions {
		display: flex;
		align-items: center;

		> div:first-of-type {
			height: 30px;
		}
	}

	.Page {
		background-color: white;
		box-sizing: border-box;
	}

	.Basics, .PayloadBuilder {
		padding: $spacing12 $spacing24 $spacing24;
	}

	.Basics {
		.Url {
			input {
				width: 566px;
			}
		}
	}

	.InnerPrompt {
		margin-top: 24px;

		h2 {
			@include fontSize18;
			margin: 0 0 $spacing16 0;
			font-weight: $fontWeightRegular;
		}

		div {
			border: 1px solid var(--color-neutral-300);
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 275px;
			height: 50px;
			padding: 0 $spacing16;
			border-radius: $sizing8;
			box-sizing: border-box;

			&:hover {
				border-color: var(--color-primary-400);
				cursor: pointer;
			}
		}
	}

	.AdditionalOptions {
		padding: $spacing12 $spacing24;

		h2 {
			@include fontSize18;
			margin: 0 0 $spacing16 0;
			font-weight: $fontWeightRegular;
		}

		.Notifications {
			margin-top: $spacing16;
		}

		.Headers {
			margin-top: $spacing16;

			.HeadersInput {
				margin-top: $spacing12;
			}
		}

		.RetrySettings {
			display: flex;
			margin-top: $spacing16;
			padding-bottom: $spacing8;

			label {
				font-size: 15px;
			}

			input {
				max-width: 150px;

				&:first-of-type {
					margin-right: $spacing8;
				}
			}

			.Delay {
				position: relative;
				span {
					position: absolute;
					right: 16px;
					top: 38px;
					color: var(--color-neutral-600);
				}
			}
		}
	}
}

.SendTestModal {
	padding: $spacing24;
	display: flex;
	flex-direction: column;
	min-height: 225px;
	justify-content: space-between;

	h1 {
		@include fontSize20;
		font-weight: $fontWeightMedium;
		margin: 0 0 $spacing16 0;

		+ p {
			margin: 0;
		}
	}

	.Loading {
		display: flex;
		align-items: center;
		p {
			padding-right: $spacing8;
		}
	}

	.Response {
		> div:first-of-type {
			background-color: var(--color-primary-100);
			padding: $spacing12 $spacing12 0;
			display: flex;
			align-items: center;
			margin-top: $spacing24;
			justify-content: space-between;

			p {
				margin: 0;
				font-weight: $fontWeightMedium;
			}

			span {
				@include fontSize14;
				color: var(--color-neutral-600);
			}
		}

		.Code {
			margin-bottom: $spacing24;
			background-color: var(--color-primary-100);

			h5 {
				padding-left: $spacing12;
				padding-top: $spacing12;
				margin-bottom: $spacing8;
				color: var(--color-neutral-700);
			}
		}
	}

	.Footer {
		span {
			 margin-left: $spacing12;
			font-weight: $fontWeightSemibold;
			cursor: pointer;
			color: var(--color-neutral-600);
		}
	}
}

.FailureNotificationSetup {
	border: 1px solid var(--color-neutral-300);
	border-radius: $sizing8;
	padding: $spacing12;
	box-sizing: border-box;
	margin-top: $spacing16;
}

.Subscriber {
	display: flex;

	.CustomEmail {
		input {
			height: 35px;
			width: 236px;
			border-color: var(--color-neutral-300);
			margin-right: $spacing8;
		}
	}

	.DropdownThin {
		max-width: 250px;
		margin-right: $spacing8;

		> div:first-child {
			height: 35px;
		}

		> div > div > div > div { // placeholder
			margin-top: 0;
		}
	}

	.SubscriberTypes {
		min-width: 220px;
		max-width: 220px;
	}

	.DeleteButton {
		&:hover {
			cursor: pointer;

			circle {
				stroke: var(--color-primary-300);
			}
			path {
				fill: var(--color-primary-400);
			}
		}
	}
}

.AddButton {
	margin-top: $spacing8;
	display: inline-block;

	&:hover {
		cursor: pointer;

		circle {
			fill: var(--color-primary-100);
			stroke: var(--color-primary-100);
		}
	}
}

:global {
	.WebhooksHiddenActions {
		p {
			margin: 0 0 $spacing12 0;

			&:hover {
				cursor: pointer;
				color: var(--color-primary-500);
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}