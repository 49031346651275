@import "~variables";

.WebhooksList {
	margin: $spacing12;
	box-sizing: border-box;
	height: 100%;
	padding: $spacing16;

	.Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid var(--color-neutral-200);
		padding: $spacing16;
		box-sizing: border-box;
		border-radius: $spacing4;

		h1 {
			@include fontSize20;
			color: var(--color-neutral-900);
			font-weight: $fontWeightMedium;
			margin: 0 0 $spacing16 0;
		}

		p {
			margin: 0;
			@include fontSize14;
			color: var(--color-neutral-700);
		}
	}

	.WebhooksTable {
		margin-top: $spacing16;
	}
}



.WebhookSetup {
	display: grid;
	grid-template-columns: 610px minmax(350px, auto);
	height: 100%;
	box-sizing: border-box;

	.Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $spacing24 $spacing24 0;
		background-color: white;
		border-top: 1px solid var(--color-neutral-200);

		> div:first-of-type {
			display: flex;
			align-items: center;
			margin: 0 0 $spacing12 0;

			h1 {
				margin: 0 0 0 $spacing8;
			}

			svg {
				&:hover {
					cursor: pointer;

					circle {
						fill: var(--color-neutral-100);
					}
				}
			}
		}
	}

	.Actions {
		display: flex;
		align-items: center;

		> div:first-of-type {
			height: 30px;
		}
	}

	h1 {
		@include fontSize24;
		margin: 0 0 $spacing12 0;
		font-weight: $fontWeightRegular;
	}

	.Page {
		background-color: white;
		box-sizing: border-box;
	}

	.Basics, .PayloadBuilder {
		padding: $spacing12 $spacing24 $spacing24;
		border-bottom: 1px solid var(--color-neutral-200);
	}

	.Basics {
		.Url {
			min-width: 100%;

			input {
				width: 100%;
			}
		}
	}

	.PayloadBuilder {
		h2 {
			@include fontSize18;
			margin: $spacing16 0 $spacing8 0;
			font-weight: $fontWeightRegular;

			+ span {
				@include fontSize14;
				color: var(--color-neutral-600);
			}
		}

		.PayloadOptions {
			display: flex;
			flex-wrap: wrap;
			margin-top: $spacing16;
		}

		.PayloadOption {
			@include fontSize14;
			background-color: var(--color-neutral-200);
			padding: $sizing4 $sizing12;
			letter-spacing: 0.5px;
			margin-right: $spacing8;
			margin-bottom: $sizing8;
			border-radius: $sizing12;
			position: relative;

			svg {
				margin-right: $sizing4;
			}

			&:hover {
				cursor: pointer;
				background-color: var(--color-primary-100);
				color: var(--color-primary-800);
			}

			&.Selected {
				background-color: var(--color-primary-200);
				color: var(--color-primary-800);

				&:hover {
					cursor: pointer;
					background-color: var(--color-primary-200) !important;
				}
			}
		}

		.CustomProperties {
			h3 {
				@include fontSize16;
				font-weight: $fontWeightRegular;
				margin: $spacing16 0 $spacing8 0;
			}
		}
	}

	.AdditionalOptions {
		padding: $spacing24;

		h2 {
			@include fontSize18;
			margin: 0 0 $spacing16 0;
			font-weight: $fontWeightRegular;
		}

		.Notifications {
			margin-top: $spacing16;
		}

		.Headers {
			margin-top: $spacing16;

			.HeadersInput {
				margin-top: $spacing12;
			}
		}

		.RetrySettings {
			display: flex;
			margin-top: $spacing16;
			padding-bottom: $spacing8;

			label {
				font-size: 15px;
			}

			input {
				max-width: 150px;

				&:first-of-type {
					margin-right: $spacing8;
				}
			}

			.Delay {
				position: relative;
				span {
					position: absolute;
					right: 16px;
					top: 38px;
					color: var(--color-neutral-600);
				}
			}
		}
	}

	.PayloadPreview {
		background-color: #132859;
		border-left: 1px solid var(--color-neutral-200);

		.Header {
			background-color: var(--color-primary-900);
			h1 {
				color: white;
				font-weight: $fontWeightSemibold;
				@include fontSize20;
			}
		}

		.Code {
			padding: 0 $spacing12 $spacing12;
		}

		h5 {
			padding-left: $spacing12;
			padding-top: $spacing8;
			padding-bottom: $spacing8;
			font-weight: $fontWeightSemibold;
			color: var(--color-primary-400);
			@include fontSize12;
		}
	}
}

.SendTestModal {
	padding: $spacing24;
	display: flex;
	flex-direction: column;
	min-height: 225px;
	justify-content: space-between;

	h1 {
		@include fontSize20;
		font-weight: $fontWeightMedium;
		margin: 0 0 $spacing16 0;

		+ p {
			margin: 0;
		}
	}

	.Loading {
		display: flex;
		align-items: center;
		p {
			padding-right: $spacing8;
		}
	}

	.Response {
		> div:first-of-type {
			background-color: var(--color-primary-100);
			padding: $spacing12 $spacing12 0;
			display: flex;
			align-items: center;
			margin-top: $spacing24;
			justify-content: space-between;

			p {
				margin: 0;
				font-weight: $fontWeightMedium;
			}

			span {
				@include fontSize14;
				color: var(--color-neutral-600);
			}
		}

		.Code {
			margin-bottom: $spacing24;
			background-color: var(--color-primary-100);

			h5 {
				padding-left: $spacing12;
				padding-top: $spacing12;
				margin-bottom: $spacing8;
				color: var(--color-neutral-700);
			}
		}
	}

	.Footer {
		span {
			 margin-left: $spacing12;
			font-weight: $fontWeightSemibold;
			cursor: pointer;
			color: var(--color-neutral-600);
		}
	}
}

.FailureNotificationSetup {
	border: 1px solid var(--color-neutral-300);
	border-radius: $sizing8;
	padding: $spacing12;
	box-sizing: border-box;
	margin-top: $spacing16;
}

.Subscriber {
	display: flex;

	.CustomEmail {
		input {
			height: 35px;
			width: 236px;
			border-color: var(--color-neutral-300);
			margin-right: $spacing8;
		}
	}

	.DropdownThin {
		max-width: 250px;
		margin-right: $spacing8;

		> div:first-child {
			height: 35px;
		}

		> div > div > div > div { // placeholder
			margin-top: 0;
		}
	}

	.SubscriberTypes {
		min-width: 220px;
		max-width: 220px;
	}

	.DeleteButton {
		&:hover {
			cursor: pointer;

			circle {
				stroke: var(--color-primary-300);
			}
			path {
				fill: var(--color-primary-400);
			}
		}
	}
}

.AddButton {
	margin-top: $spacing8;
	display: inline-block;

	&:hover {
		cursor: pointer;

		circle {
			fill: var(--color-primary-100);
			stroke: var(--color-primary-100);
		}
	}
}

:global {
	.WebhooksHiddenActions {
		p {
			margin: 0 0 $spacing12 0;

			&:hover {
				cursor: pointer;
				color: var(--color-primary-500);
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}