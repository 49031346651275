@import "~variables";

.Header {
  display: flex;
  background-color: #fff;
  height: 55px;
  padding: 0 $spacing24;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;

  > div {
    display: flex;
    align-items: center;
  }

  @media print {
    display: none;
  }
}

.Logo {
  max-width: 128px;
  max-height: 128px;
  margin-right: 12px;
}

.IntroVideoBtn {
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #BFD0F7;
  border-radius: 30px;
  padding: 0 $spacing4;
  margin-right: $spacing16;

  span {
    padding-right: $spacing8;
    padding-left: 6px;
    font-size: 15px;
    font-weight: $fontWeightMedium;
    color: var(--color-primary-800);
  }

  &:hover {
    background-color: var(--color-primary-200);
    cursor: pointer;
  }
}

.ModalVideo {
  video {
    border-radius: $sizing8;
  }
}

.Nav {
  margin-left: $spacing16;
  display: flex;

  @include xsmall-screen-only {
    display: none;
  }

  .Item {
    padding: 0 $spacing12;

    a {
      color: var(--color-neutral-800);
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.5px;
      font-weight: $fontWeightMedium;
      @include fontSize16;
      display: flex;
      align-items: center;
      height: 55px;

      &.active {
        color: var(--color-primary-600);
      }
    }
  }
}

.NavItemActiveMarker {
  width: 100px;
  height: 3px;
  background-color: var(--color-primary-400);
  position: fixed;
  top: 0;
  transition: transform 0.3s ease, width 0.2s linear;
  visibility: hidden;
  will-change: width, transform, visibility;

  @include xsmall-screen-only {
    display: none;
  }
}

.MobileMenuIcon {
  margin-left: $spacing24;
  cursor: pointer;
  display: none;

  @include xsmall-screen-only {
    display: block;
  }
}
