@import "~variables";

.Sidebar {
	display: flex;
	flex-direction: column;
	background-color: var(--color-neutral-100);
	box-sizing: border-box;
	margin: $spacing16 $spacing16 0;
	z-index: 2;
	position: relative;

	@include small-screen {
		width: 650px;
		margin: $spacing16 auto;
	}

	@include medium-screen {
		width: 100%;
		margin: 0;
		padding: $spacing16;
	}

	@include large-screen {
		padding: $spacing24;
	}

	.Top {
		display: flex;
		flex-direction: column;

		@include small-screen {
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
		}

		@include medium-screen {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}

	.Settings {
		display: flex;
		align-items: center;

		.HiddenSettings {
			margin-left: $spacing8;
		}
	}

	.WorkflowName {
		margin-top: $spacing16;
		margin-left: $spacing8;
		display: flex;
		align-items: center;

		@include medium-screen {
			display: block;
		}

		h2 {
			font-weight: $fontWeightMedium;
			@include fontSize20;
			margin: 0;
		}

		@include small-screen {
			h2 {
				@include fontSize24;
			}
			margin: 0;
		}

		@include medium-screen {
			h2 {
				@include fontSize20;
			}

			margin-top: $spacing24;
			margin-left: $spacing8;
		}
	}

	.JobStatus {
		display: inline-block;
		background-color: var(--color-neutral-200);
		border-radius: 10px;
		color: var(--color-neutral-800);
		padding: 5px 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		@include fontSize12;
		font-weight: $fontWeightSemibold;
		margin-left: $spacing16;

		@include medium-screen {
			margin-left: 0;
			margin-top: $spacing16;
		}

		&.Open {
			background-color: var(--color-accent-blue-200);
			color: var(--color-accent-blue-800);
		}

		&.Delayed {
			background-color: var(--color-accent-yellow-100);
			color: var(--color-accent-yellow-900);
		}
	}

	.ViewDropdown {
		min-width: 180px;
		max-width: 225px;
		width: 100%;
		border-radius: 25px !important;

		> div:first-of-type {
			border-radius: 25px;
			height: 40px;
			border-color: transparent;
		}

		>div:nth-of-type(2) {
			margin-top: $sizing4;
			border-radius: $sizing8;
		}

		@include large-screen {
			width: 225px;
		}
	}

	.Details {
		display: flex;
		margin-left: $spacing8;
		margin-top: $spacing8;
		align-items: center;

		@include small-screen {
			margin-left: 0;
		}

		@include medium-screen {
			align-items: flex-start;
			flex-direction: column;
			margin-left: $spacing8;
		}

		.Title {
			@include fontSize12;
			color: var(--color-neutral-700);
			text-transform: uppercase;
			letter-spacing: 0.8px;
		}
	}

	.StartedDate {
		margin-right: $spacing48;

		@include small-screen {
			margin-right: 100px;
		}

		@include medium-screen {
			margin-right: 0;
			margin-top: $spacing8;
		}
	}

	.Collaborators {
		@include medium-screen {
			margin-top: $spacing16;
		}

		img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-right: $spacing8;
		}

		> div:first-of-type {
			display: flex;
		}
	}
}

:global {
	.HiddenSettingsPopover {
		p {
			margin: 0 0 $spacing8 0;
			cursor: pointer;

			&:hover {
				color: var(--color-primary-500);
			}
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}
}