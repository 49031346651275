@import "~variables";

.UserInviteJoin {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: $spacing48;
}

.SectionHeading {
	h2, p {
		text-align: center;
	}

	h2 {
		margin-bottom: 0;
	}

	p {
		color: var(--color-neutral-600);
		font-size: 15px;
	}
}