@import "~variables";

.ForgotPassword {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;

	.Logo {
		margin-top: $spacing48;
		margin-bottom: $spacing24;
	}

	.Header {
		text-align: center;
		width: 400px;

		h1 {
			@include fontSize24;
			margin: 0 0 $spacing8 0;
		}

		p {
			margin: 0;
			font-size: 15px;
			color: var(--color-neutral-600);
		}
	}

	.Form {
		&.Hide {
			visibility: hidden;
			position: absolute;
		}
	}
}

.Done {
	display: flex;
	flex-direction: column;
	position: absolute;
	visibility: hidden;
	z-index: -2;
	opacity: 0.5;
	transform: translateY(10px);
	transition: 0.3s ease-out opacity, 0.3s ease-out transform;

	&.Show {
		transform: translateY(0);
		z-index: 2;
		opacity: 1;
		visibility: visible;
		position: relative;
	}

	.Status {
		background-color: white;
		display: flex;
		border-radius: $sizing8;
		align-items: center;
		padding: $spacing24;
		@include shadowCloseNeutral;
	}

	.Status > div:nth-of-type(2) {
		padding-left: $spacing16;
		box-sizing: border-box;

		h3 {
			@include fontSize18;
			margin: 0 0 $spacing8;
			font-weight: $fontWeightMedium;
			color: var(--color-neutral-900);
		}

		span {
			@include fontSize14;
			color: var(--color-neutral-700);
		}
	}

	.EmailProviderLinks {
		margin-top: $spacing16;
		display: flex;

		> div {
			padding: $spacing8 $spacing12;
			margin-right: $spacing8;
			display: flex;
			align-items: center;
			background-color: white;
			border-radius: 6px;
			@include shadowCloseNeutral;

			p {
				margin: 0 0 0 $spacing8;
			}

			a {
				text-decoration: none;
				color: var(--color-neutral-800);
			}

			a div {
				display: flex;
			}
		}
	}
}