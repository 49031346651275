@import "~variables";

.SystemError {
	position: absolute;
	display: none;
	bottom: $spacing24;
	right: $spacing24;
	z-index: 999;
	background-color: white;
	padding: $spacing16;
	box-sizing: border-box;
	width: 437px;
	@include shadowDistantNeutral;
	border-radius: $sizing8;
	border-top: 7px solid var(--color-accent-red-500);
	visibility: hidden;
	transform: translateX(100%);
	transition: 0.2s ease-in transform;

	&.Show {
		display: block;
		transform: translateX(0);
		visibility: visible;
	}

	p {
		max-width: 370px;
	}

	a {
		color: var(--color-neutral-800);
		text-decoration: underline;
	}

	.ReportBtn {
		padding: $spacing16 0 $spacing12 0;
		span {
			color: var(--color-neutral-900);
			text-transform: uppercase;
			font-weight: $fontWeightMedium;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.ErrorReportModal {
	overflow: hidden;
}

.ErrorReportForm {
	padding: $spacing24;

	.Header {
		margin-bottom: $spacing32;

		h2 {
			@include fontSize24;
			font-weight: $fontWeightRegular;
			margin-bottom: $spacing8;
		}

		span {
			@include fontSize14;
			color: var(--color-neutral-700);
		}
	}
}

.SuccessMessage {
	padding: $spacing48 $spacing24;
	display: flex;
	flex-direction: column;
	align-items: center;
	visibility: hidden;
	position: absolute;
	z-index: -1;
	transform: translateY(50%);
	transition: 0.2s ease-out transform, 0.3s ease-out visibility;

	p {
		margin-top: $spacing24;
		font-weight: $fontWeightMedium;
	}
}

.LostInternetConnection {
	padding: $spacing24;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	h2 {
		@include fontSize20;
		font-weight: $fontWeightMedium;
	}

	p {
		line-height: 22px;
	}
}