@import "~variables";

.Unauthorized {
	padding: $spacing24;

	h1 {
		@include fontSize20;
		font-weight: $fontWeightRegular;
		margin: 0;
	}
}