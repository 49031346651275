@import "~variables";

.Step {
	border: 1px solid #EBEFF5;
	padding: 20px $spacing16;

	&.isDeleted {
		h4 {
			color: var(--color-neutral-600);
			text-decoration: line-through;
			cursor: default;
		}
	}

	@include small-screen {
		border-radius: $sizing8;
	}

	&.isExpanded {
		margin-bottom: $spacing8;
		padding: 20px $spacing16 $spacing24;
	}

	&.isCollapsed {
		&:hover {
			cursor: pointer;
			box-shadow: 0 1px 2px rgba(39, 54, 89, 0.15);
			padding-bottom: 28px;
			transition: 0.2s ease-out padding-bottom;
		}
	}
}

.Head {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h4 {
		@include fontSize20;
		font-weight: $fontWeightRegular;
		margin: 0;
	}
}

.CompletedDetails {
	display: flex;
	align-items: flex-start;
	margin-top: $spacing12;

	.Title {
		color: var(--color-neutral-600);
		@include fontSize14;
	}

	img {
		width: 26px;
		max-width: 26px;
		height: 26px;
	}

	.CompletedBy {
		margin-left: 64px;

		.Title {
			margin-bottom: $sizing8;
		}
	}
}

.Assets {
	margin-top: $spacing16;

	p {
		font-size: 0.875rem;
		line-height: 1rem;
		color: var(--color-neutral-700);
		margin: 0 0 .5rem 0;
	}

	.Images {
		display: flex;
		flex-wrap: wrap;
	}

	img {
		border: 1px solid var(--color-neutral-200);
		box-sizing: border-box;
		border-radius: 2px;
		width: 100%;
		max-width: 100px;
		min-width: 100px;
		height: 70px;
		object-fit: cover;
		margin-right: $spacing4;

		&:hover {
			cursor: zoom-in;
		}
	}
}
.Form {
	margin-top: $spacing16;
}

.Tasks {
	padding-top: $spacing8;

	p {
		color: var(--color-neutral-700);
		font-size: 15px;
	}

	.Task:last-of-type {
		margin-bottom: 0;
	}
}

.Task {
	display: flex;
	align-items: center;
	margin-bottom: $spacing12;

	svg {
		margin-right: $spacing12;
	}
}