@import "~variables";

.JobOverview {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto;
	grid-column-gap: 0;
	grid-template-areas: "sidebar" "main";

	box-sizing: border-box;
	border-top: 1px solid var(--color-neutral-200);

	@include medium-screen {
		grid-template-columns: 268px auto;
		grid-template-rows: 1fr;
		grid-column-gap: 0;
		grid-template-areas: "sidebar main";
	}

	@include large-screen {
		grid-template-columns: 326px auto;
	}
}

.Main {
	background-color: var(--color-primary-100);

	@include small-screen {
		background-color: white;
	}
}
