@import "~variables";


.OpenSteps {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: $spacing12;
	min-height: 250px;

	&.Hide {
		min-height: 0;
		height: 0;
		z-index: -1;
		transition: 0.3s cubic-bezier(0.35, 0.35, 0.47, 0.98) visibility, 0.5s cubic-bezier(0.35, 0.35, 0.47, 0.98) min-height;
		visibility: hidden;
		margin-top: 0;
		margin-bottom: 0;

		p {
			visibility: hidden;
			transition: none;
		}
	}

	> div:first-of-type {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 650px;

		> p:first-of-type {
			@include fontSize14;
			color: var(--color-neutral-700);
			text-transform: uppercase;
			letter-spacing: 0.8px;
			margin-left: $spacing24;

			@include small-screen {
				margin-left: 0;
			}
		}
	}

	@include medium-screen {
		margin: $spacing12 $spacing12 $spacing32 $spacing12;
		align-items: flex-start;
		min-height: 308px;
	}

	@include large-screen {
		margin: $spacing12 $spacing12 $spacing32 $spacing16;
	}
}

.OpenStep {
	border-top: 1px solid var(--color-primary-200);
	border-bottom: 1px solid var(--color-primary-200);
	transition: 0.3s cubic-bezier(0.35, 0.35, 0.47, 0.98) transform, 0.3s cubic-bezier(0.35, 0.35, 0.47, 0.98) visibility;
	z-index: -1;
	transform: translateX(-60%);
	visibility: hidden;

	&.Show {
		transform: translateX(0);
		margin-bottom: $spacing12;
		position: relative;
		z-index: 1;
		visibility: visible;
	}
	&.Hide {
		transform: translateX(0) translateY(20%) !important;
		transition: 0.3s cubic-bezier(0.35, 0.35, 0.47, 0.98) transform, 0.3s cubic-bezier(0.35, 0.35, 0.47, 0.98) visibility, 0.3s cubic-bezier(0.35, 0.35, 0.47, 0.98) z-index;
	}

	@include small-screen {
		border: 1px solid #bfd0f7;
		border-radius: $sizing8;
	}

	@include medium-screen {
		min-height: 250px;
	}
}

.StepDetails {
	box-sizing: border-box;
	background-color: white;
	width: 100%;
	max-width: 650px;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto auto;
	grid-template-areas: "details" "assignees" "actions";
	transition: 0.2s ease-in transform, 0.2s ease-in opacity, 0.2s ease-in visibility;

	&.ShowCompletedContent {
		display: flex;
	}

	@include small-screen {
		grid-template-columns: auto 230px;
		grid-template-rows: 188px 60px;
		grid-template-areas: "details assignees" "actions actions";
		border-radius: $sizing8;
	}

	@include medium-screen {
		max-width: 100%;
	}

	.BasicDetails {
		padding: $spacing24;
		grid-area: details;

		h3 {
			@include fontSize20;
			margin: 0 0 $spacing12;
			font-weight: $fontWeightRegular;
		}

		span {
			@include fontSize12;
			text-transform: uppercase;
			color: var(--color-neutral-700);
			letter-spacing: 0.6px;
		}
	}

	.ShowCompletedContentBtn {
		margin-top: $spacing24;
	}

	.Assignees {
		grid-area: assignees;
		padding: 0 $spacing24 $spacing24;
		border-top-right-radius: $sizing8;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include small-screen {
			background-color: var(--color-primary-100);
			padding: $spacing8;
		}

		.Title {
			display: flex;
			justify-content: space-between;
			@include fontSize14;
			color: var(--color-neutral-700);
			margin-bottom: $spacing8;
		}

		.CompleterCounter {
			width: 145px;
			@include fontSize20;
			margin-right: $spacing16;

			span {
				color: var(--color-neutral-600);
			}

			p {
				@include fontSize12;
				display: inline-block;
				color: var(--color-neutral-500);
				text-transform: uppercase;
				margin-left: $spacing4;
			}
		}

		.ChangeAssignmentsButton {
			@include fontSize12;
			padding: 2px 4px;
			border: 1px solid var(--color-neutral-200);
			border-radius: $sizing4;
			margin-left: $spacing8;
			background-color: white;

			&:hover {
				cursor: pointer;
				background-color: var(--color-primary-200);
				color: var(--color-primary-600);
				border-color: var(--color-primary-200);
			}
		}

		.Unassigned {
			background-color: white;
			display: flex;
			align-items: center;
			padding: 4px 16px 4px 4px;
			border: 1px solid var(--color-neutral-200);
			border-radius: 20px;
			max-width: 200px;

			span {
				margin-left: $spacing8;
			}

			&.canAssign {
				&:hover {
					cursor: pointer;
					border-color: var(--color-primary-200);

					.Icon_Bg {
						fill: var(--color-primary-800)
					}

					.Icon_Head {
						fill: var(--color-primary-300)
					}

					.Icon_Body {
						fill: var(--color-primary-400)
					}

					span {
						color: var(--color-primary-700);
					}
				}
			}
		}
	}

	.Actions {
		grid-area: actions;
		padding: $spacing16;
		border-top: 1px solid var(--color-primary-200);
		display: flex;
		align-items: center;

		> div:first-of-type {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;
		}

		.MarkAsComplete {
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: $spacing12;
			margin: -12px;
			svg {
				margin-right: $spacing8;
			}

			&:hover {
				p {
					color: var(--color-primary-500);
				}
			}
		}

		.Locked {
			cursor: not-allowed;
			display: flex;
			p {
				color: var(--color-neutral-700);
			}
		}

		> div:first-of-type a {
			display: flex;
			cursor: pointer;

			&:hover {
				svg {
					transform: translateX(5px);
				}
			}
		}

		p {
			margin: 0;
			color: var(--color-primary-800);
			text-transform: uppercase;
			letter-spacing: 0.5px;
			font-weight: $fontWeightMedium;
		}

		svg {
			margin-left: $spacing8;
			transition: 0.2s ease-out transform;
		}
	}
}

.CompletedContent {
	display: flex;
	padding: $spacing16;
	visibility: hidden;
	position: absolute;
	z-index: -1;
	opacity: 0;
	transform: translateY(10px);
	transition: 0.2s ease-in transform, 0.2s ease-in opacity;

	h2 {
		@include fontSize20;
		font-weight: $fontWeightRegular;
		margin: $spacing4 0 $spacing24;
	}

	.BackButon {
		display: inline-block;
		margin-right: $spacing16;

		svg {
			cursor: pointer;

			&:hover {
				circle {
					fill: var(--color-neutral-100);
				}
			}
		}
	}
}