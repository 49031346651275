@import "~variables";

.Container {
	min-width: 864px;
	max-height: 800px;
	width: 864px;
	margin: auto;
	box-sizing: border-box;
}

.Back {
	margin-top: $spacing16;
	display: flex;
	align-items: center;

	@media print {
		display: none;
	}

	svg {
		margin-right: 6px;
	}
	a {
		@include fontSize14;
	}
}

.Receipt {
	margin-top: $spacing16;
	border: 1px solid var(--color-neutral-300);
	border-radius: $sizing4;
	height: 850px;
	min-height: 850px;
	background-color: white;
	padding: $spacing32;

	@media print {
		padding: 0;
		margin: 0;
		border: 0;
	}

	.Header {
		display: flex;
		justify-content: space-between;

		> div {
			display: flex;
			align-items: center;
		}

		h1 {
			@include fontSize24;
			font-weight: $fontWeightSemibold;
			margin: 0 $spacing16 0 0;
			color: var(--color-neutral-900);

			@media print {
				@include fontSize30;
			}
		}

		button {
			@media print {
				display: none;
			}
		}
	}
}

.InvoiceOverview {
	display: flex;
	margin-top: $spacing24;

	p {
		font-size: 15px;
		margin: $spacing8 0 0;
	}

	> div:first-of-type {
		width: 150px;
	}

	.Brand {
		text-transform: uppercase;
	}
}

.Addresses {
	display: flex;
	margin-top: $spacing48;
	justify-content: space-between;

	p {
		font-size: 15px;
		margin: $spacing8 0 0;
	}

	> div:nth-of-type(2) {
		margin-right: 200px;
	}
}

.Table {
	margin-top: 50px;

	> div:last-of-type {
		border-bottom: 0;
	}

	.Row {
		display: grid;
		grid-template-columns: 330px 143px 140px 187px;
		margin-bottom: $spacing12;
		padding-bottom: $spacing12;
		border-bottom: 1px solid var(--color-neutral-200);
		align-items: center;
		font-size: 15px;

		span:last-of-type {
			text-align: right;
		}
	}

	.Row:first-of-type {
		border-bottom: 1px solid var(--color-neutral-400);
		margin-bottom: $spacing16;
		padding-bottom: 0;
		span {
			font-size: 13px;
			padding-bottom: 16px;
		}
	}

	.UnitPrice {
		font-size: 13px;
	}
}

.Totals {
	width: 368px;
	display: flex;
	margin-left: auto;
	margin-top: 150px;
	flex-direction: column;

	> div {
		display: flex;
		justify-content: space-between;
		border-top: 1px solid var(--color-neutral-200);
		padding-bottom: 8px;
	}

	p {
		font-size: 15px;
		margin: $spacing8 0 0;
	}
}

.ReceiptsLoader {
	max-width: 648px;
	width: 100%;
	background-color: white;
	border: 1px solid var(--color-neutral-200);
	border-radius: $sizing8;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: $spacing16 auto auto;
	min-height: 250px;
}

.ReceiptsList {
	max-width: 648px;
	width: 100%;
	background-color: white;
	border: 1px solid var(--color-neutral-200);
	border-radius: $sizing8;
	padding: $spacing12;
	box-sizing: border-box;
	margin: $spacing16 auto auto;
	max-height: 500px;
	overflow-y: scroll;

	.Row, .Header {
		display: grid;
		grid-template-columns: 200px 330px 200px;
		margin-bottom: $spacing12;
		padding-bottom: $spacing12;
		border-bottom: 1px solid var(--color-neutral-200);
		align-items: center;
		font-size: 15px;
	}

	.Header {
		span {
			font-weight: $fontWeightMedium;
		}

		border-color: var(--color-neutral-300);
	}

	.Row:last-of-type {
		border: 0;
		padding-bottom: 0;
	}
}